// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

//Modal
$modal-sm:  90%;
$modal-md:  90%;
$modal-lg:  90%;
//$modal-xl:  90%;
